import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid mr-0" }
const _hoisted_2 = { class: "col-11" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductionStage = _resolveComponent("ProductionStage")!
  const _component_Card = _resolveComponent("Card")!
  const _component_SalesDialog = _resolveComponent("SalesDialog")!
  const _component_NotificationCard = _resolveComponent("NotificationCard")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_Card, null, {
          content: _withCtx(() => [
            _createVNode(_component_ProductionStage, {
              isResultView: _ctx.isResultView,
              showSelectionCheckbox: true,
              onLastUpdated: _ctx.isDataUpdated,
              filename: _ctx.filename,
              "production-stage": _ctx.productionStage,
              "dynamic-columns": _ctx.dynamicColumns,
              "show-split-button": true,
              "show-new-button": true,
              "new-button-text": "Add",
              "show-delete-button": true,
              "show-export-button": true,
              "show-copy-button": true,
              commandName: "CW.MAN.WIP",
              "page-title": "Work In Progress"
            }, null, 8, ["isResultView", "onLastUpdated", "filename", "production-stage", "dynamic-columns"])
          ]),
          _: 1
        })
      ])
    ]),
    _createVNode(_component_SalesDialog, {
      order: this.salesOrder,
      show: this.showSalesDialog,
      readOnly: this.readOnly,
      showLoadExistingQuoteBtn: true,
      dialogReadOnly: false,
      onHide: _cache[0] || (_cache[0] = 
      (hidden) => {
        this.salesOrder = null;
        this.showSalesDialog = hidden;
      }
    ),
      onOnSave: _cache[1] || (_cache[1] = 
      (event) => {
        this.fetchFilteredOrders(false)
      }
    )
    }, null, 8, ["order", "show", "readOnly"]),
    _createVNode(_component_NotificationCard)
  ], 64))
}